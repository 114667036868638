<template>
  <v-card>
      <v-data-table
      :headers="headers"
      :items="users">
        <template slot="top">
            <v-toolbar
        flat
      >
        <v-toolbar-title>Invitados</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="editDialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              New Item
            </v-btn>
          </template>
          
          <sub-user-form @close='close' :editedItem='item' :allDevices="devicesAll" />

        </v-dialog>
      </v-toolbar>


        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item ({{deletingItem.id}}-{{deletingItem.name}})?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </template>


        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="initialize"
            >
                Reset
            </v-btn>
        </template>
          
      </v-data-table>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    components:{
        SubUserForm: () => import('./newSubUser.vue')
    },
    props:{
        devicesAll:{
            type: Array,
            default:()=>{[]}
        }
    },
    data:() =>({
        headers:[
            {text:'Id',value:'id'},
            {text:'Nombre',value:'name'},
            {text:'Username',value:'email'},
            {text:'Creado',value:'created'},
            {text:'Activo',value:'active'},
             { text: 'Actions', value: 'actions', sortable: false },
            ],
        users:[],
        dialogDelete:false,
        deletingItem:{},
        editDialog:false,
        item:{}
    }),
    mounted(){
        
        this.initialize();
        
    },
    computed:{
      formTitle () {
        return this.item.id == undefined ? 'New Item' : 'Edit Item';
      },
      currentUserId(){
          return this.$route.params?.id || this.$store.state.user.id;
      }
    },
    methods:{
        ...mapActions(['getSubUsers','deleteUser','showSnackbar']),
        initialize(){
            this.getSubUsers(this.currentUserId).then(res=>{
            //console.log(res);
            this.users = res.data.data
        });
        },

        editItem (item) {
            //console.log(item);
            //this.editedIndex = this.desserts.indexOf(item)
            this.item = Object.assign({}, item)
            this.editDialog = true;
        },

        deleteItem (item) {
            //Set the item to be displayed on dialog and use it when confirm deletion
            this.deletingItem = item;
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            //use deletingItem to remove user
            this.deleteUser(this.deletingItem.id).then((res)=>{
                if(res != undefined)
                this.showSnackbar({
                    showing:true,
                    message: 'User '+res.data.data.id +' deleted.',
                    color:'success'
                });
            }).catch(err =>{ 
                console.log(err);
                    if(err.data != undefined && err.data.errors != undefined)
                        this.showSnackbar({
                            showing:true,
                            message: err.data.errors[0],
                            color:'error'
                        });
                    if(err.message != undefined)
                        this.showSnackbar({
                            showing:true,
                            message: err.message,
                            color:'error'
                        });
                    else
                        this.showSnackbar({
                            showing:true,
                            message: 'Error deleting user.',
                            color:'error'
                        });
            });
            this.dialogDelete = false;
            this.initialize();
        },
        closeDelete(){
            this.dialogDelete = false;
        },
        close () {
            this.editDialog = false;
            this.$nextTick(() => {
              this.item = Object.assign({}, {});
            //this.editedIndex = -1
            });
            this.initialize();
        },
        
    }
}
</script>

<style>

</style>